import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file for styling

import Header from '../Header/Header';

const PrivacyPolicy = () => {
  return (

   <>
   
   <Header />
        <br />
        <br />
        <br />
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p><em>Last updated on November 6, 2024</em></p>

      <h2>1. Our Commitment to Data Protection</h2>
      <p>
        Protecting the privacy of individuals who provide us with their personal data ("Personal Data") is of utmost importance to SolwayHR in our daily operations and when delivering our services. We value the trust you place in us when interacting with our website and application, and we are committed to maintaining that trust. To this end, we adhere to data protection legislation, including but not limited to the General Data Protection Regulation (EU) 2016/679 ("Applicable Law").
      </p>

      <h2>2. General Provisions</h2>
      <p>
        This Privacy Policy (the "Policy") describes how SolwayHR ("SolwayHR", "we", or "our") collects, uses, stores, shares, and protects your information when acting as a data controller in relation to the services offered by SolwayHR, including but not limited to services provided on or through the domain solwayhr.com (the "Website") and/or the SolwayHR application (the "Application") (collectively, the "Services").
      </p>
      <p>
        This Policy applies when you ("you", the "Client", the "User") access, visit, or use any part of the Services.
      </p>
      <p>
        For the purposes of this Policy:
      </p>
      <ul>
        <li>
          A <strong>"Client"</strong> is an individual or entity using the Application based on a subscription agreement.
        </li>
        <li>
          A <strong>"Prospect"</strong> is an individual who visits the Website out of interest in the Services and/or signs up for a demo.
        </li>
      </ul>
      <p>
        <strong>Changes to This Privacy Policy</strong>
      </p>
      <p>
        We reserve the right to modify this Privacy Policy from time to time to ensure transparency of all processing operations on your Personal Data and to keep it up to date. We may notify you of any substantial changes to this Policy before the effective date of the changes by sending you an email or through any other reasonably visible and accessible means.
      </p>
      <p>
        We recommend that you regularly review this Privacy Policy.
      </p>

      {/* Continue with the rest of the policy sections as provided above */}

      {/* For brevity, the rest of the sections are omitted. Please include all sections in your actual component. */}
    </div>
   
   
   </>
  );
};

export default PrivacyPolicy;
