import React from 'react';
import './TermsOfService.css';

import Header from '../Header/Header';

const TermsOfService = () => {
  return (
    <>
    
    <Header />
    <br />
    <br />
    <br />
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p><em>Last updated on November 6, 2024</em></p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing and using SolwayHR's services (the "Services"), you ("User" or "you") agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you must not use our Services.
      </p>

      <h2>2. Description of Services</h2>
      <p>
        SolwayHR provides human resources management solutions, including payroll management, employee data management, and related services accessible through our website and application (collectively, the "Platform").
      </p>

      <h2>3. Eligibility</h2>
      <p>
        To use the Services, you must:
      </p>
      <ul>
        <li>Be at least 18 years old or the age of majority in your jurisdiction.</li>
        <li>Have the legal capacity to enter into a binding contract.</li>
        <li>Use the Services for professional purposes only.</li>
      </ul>

      <h2>4. Account Registration</h2>
      <p>
        <strong>Account Creation:</strong> To access certain features, you may need to create an account by providing accurate and complete information.
      </p>
      <p>
        <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
      </p>
      <p>
        <strong>Notification:</strong> You agree to notify SolwayHR immediately of any unauthorized use of your account or any other security breach.
      </p>

      <h2>5. Use of the Services</h2>
      <p>
        You agree to use the Services in compliance with all applicable laws and regulations. You shall not:
      </p>
      <ul>
        <li>Use the Services for any unlawful or prohibited purpose.</li>
        <li>Share your account credentials with others.</li>
        <li>Interfere with or disrupt the integrity or performance of the Services.</li>
        <li>Upload or transmit any malicious code or content.</li>
      </ul>

      <h2>6. User Obligations</h2>
      <p>
        <strong>Accuracy of Information:</strong> You must ensure that all information you provide is accurate, current, and complete.
      </p>
      <p>
        <strong>Compliance:</strong> You agree to comply with all applicable laws, regulations, and third-party rights.
      </p>
      <p>
        <strong>Content Responsibility:</strong> You are responsible for all content you upload or transmit through the Services.
      </p>

      <h2>7. Intellectual Property Rights</h2>
      <p>
        <strong>SolwayHR's Rights:</strong> SolwayHR retains all rights, title, and interest in and to the Services, including all intellectual property rights.
      </p>
      <p>
        <strong>License Grant:</strong> SolwayHR grants you a limited, non-exclusive, non-transferable license to use the Services as per these Terms.
      </p>
      <p>
        <strong>Restrictions:</strong> You shall not modify, distribute, sell, or lease any part of the Services or reverse engineer or attempt to extract the source code.
      </p>

      <h2>8. Privacy Policy</h2>
      <p>
        Your use of the Services is also governed by our{' '}
        <a href="/privacy-policy">Privacy Policy</a>, which is incorporated into these Terms by reference.
      </p>

      <h2>9. Data Protection</h2>
      <p>
        <strong>Data Controller and Processor:</strong> For the purposes of data protection laws, the Customer (your employer or the entity you represent) is the data controller, and SolwayHR is the data processor.
      </p>
      <p>
        <strong>Compliance:</strong> SolwayHR will process personal data in accordance with applicable data protection laws and the Data Processing Agreement.
      </p>

      <h2>10. Confidentiality</h2>
      <p>
        Both parties agree to maintain the confidentiality of any proprietary or confidential information disclosed during the use of the Services.
      </p>

      <h2>11. Termination</h2>
      <p>
        <strong>By User:</strong> You may terminate your account at any time by following the procedures on the Platform.
      </p>
      <p>
        <strong>By SolwayHR:</strong> SolwayHR may suspend or terminate your access to the Services if you violate these Terms or for any other legitimate reason.
      </p>
      <p>
        <strong>Effect of Termination:</strong> Upon termination, your right to use the Services will immediately cease. SolwayHR may delete your content and data after a certain period, except as required by law.
      </p>

      <h2>12. Disclaimers</h2>
      <p>
        <strong>As-Is Basis:</strong> The Services are provided "as is" without warranties of any kind.
      </p>
      <p>
        <strong>No Warranty:</strong> SolwayHR disclaims all warranties, express or implied, including but not limited to merchantability, fitness for a particular purpose, and non-infringement.
      </p>

      <h2>13. Limitation of Liability</h2>
      <p>
        <strong>Indirect Damages:</strong> SolwayHR shall not be liable for any indirect, incidental, special, consequential, or punitive damages.
      </p>
      <p>
        <strong>Maximum Liability:</strong> SolwayHR's total liability shall not exceed the amounts paid by you to SolwayHR in the twelve (12) months preceding the claim.
      </p>

      <h2>14. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless SolwayHR from any claims, liabilities, damages, losses, and expenses arising out of your use of the Services or violation of these Terms.
      </p>

      <h2>15. Changes to the Terms</h2>
      <p>
        SolwayHR reserves the right to modify these Terms at any time. We will notify you of significant changes by posting the new Terms on the Platform or via email. Your continued use of the Services after the effective date signifies your acceptance of the revised Terms.
      </p>

      <h2>16. Governing Law and Jurisdiction</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of <strong>[Your Country]</strong>. Any disputes arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts of <strong>[Your City]</strong>, <strong>[Your Country]</strong>.
      </p>

      <h2>17. Severability</h2>
      <p>
        If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
      </p>

      <h2>18. Entire Agreement</h2>
      <p>
        These Terms, along with the Privacy Policy and any other legal notices published by SolwayHR, constitute the entire agreement between you and SolwayHR regarding the Services.
      </p>

      <h2>19. Waiver</h2>
      <p>
        No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term.
      </p>

      <h2>20. Assignment</h2>
      <p>
        You may not assign your rights or obligations under these Terms without SolwayHR's prior written consent. SolwayHR may assign its rights under these Terms without condition.
      </p>

      <h2>21. Contact Information</h2>
      <p>
        For any questions regarding these Terms or to request a PDF version, please contact SolwayHR at:
      </p>
      <ul>
        <li>
          <strong>Email:</strong>{' '}
          <a href="mailto:legal@solwayhr.com">legal@solwayhr.com</a>
        </li>
        <li>
          <strong>Address:</strong> SolwayHR, [Your Address], [Your City], [Your Country]
        </li>
      </ul>

      <h2>22. Language</h2>
      <p>
        These Terms may be translated into other languages for your convenience. In the event of any conflict between the English version and the translated version, the English version shall prevail.
      </p>

      <h2>23. Electronic Communications</h2>
      <p>
        You agree to receive communications from SolwayHR electronically, such as emails, texts, or notices posted on the Platform.
      </p>

      <h2>24. Feedback</h2>
      <p>
        Any feedback, comments, or suggestions you may provide regarding SolwayHR or the Services is entirely voluntary, and we will be free to use such feedback without any obligation to you.
      </p>
    </div>
    
    
    
    </>
  );
};

export default TermsOfService;
