// src/components/HomePage/Benefits.js

import React from 'react';
import './Benefits.css';
import benefit1 from '../../assets/images/solwayhr_logo.jpeg';
import benefit2 from '../../assets/images/solwayhr_logo.jpeg';
import benefit3 from '../../assets/images/solwayhr_logo.jpeg';

const Benefits = () => {
  const benefitsList = [
    {
      icon: benefit1,
      title: 'Increased Efficiency',
      description: 'Automate repetitive tasks to save time and reduce errors.',
    },
    {
      icon: benefit2,
      title: 'Enhanced Compliance',
      description: 'Stay compliant with labor laws and regulations effortlessly.',
    },
    {
      icon: benefit3,
      title: 'Scalable Solutions',
      description: 'Grow your HR capabilities as your business expands.',
    },
  ];

  return (
    <section className="benefits-section">
      <h2>Benefits</h2>
      <div className="benefits-grid">
        {benefitsList.map((benefit, index) => (
          <div className="benefit-card" key={index}>
            <img src={benefit.icon} alt={`${benefit.title} Icon`} />
            <h3>{benefit.title}</h3>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Benefits;
