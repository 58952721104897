import React, { createContext, useState, useEffect, useRef } from 'react';
import axios from '../utils/axiosInstance';

// Manages global authentication state.
// Provides auth and setAuth to the entire application.

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        accessToken: null,
        userId: null, 
        username: null, 
        email: null, 
        roles: [],
            

        });

        const [loading, setLoading] = useState(true); // Add a loading state
        const effectRan = useRef(false);
      
        useEffect(() => {


            if ( effectRan.current===false ) {
          const verifyRefreshToken = async () => {
            try {
             const response = await axios.post('/auth/refresh');
             const data = response.data;
            
             let accessToken = data.accessToken;
             let userId = data.userId;
             let username = data.username;
             let email = data.email;
             let roles = data.roles;
            
             setAuth({ accessToken, userId, username, email, roles });

            } catch (error) {
              console.error('Error refreshing token on startup:', error);
            } finally {
              setLoading(false); // Set loading to false after attempting refresh
            }
          };
      
          verifyRefreshToken();

          return () => {
            effectRan.current = true;
          };

        }


        }, []);
    
    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
           {!loading ? children : null}
        </AuthContext.Provider>
    );
};

export default AuthProvider;







