// src/components/Header/Header.js

import React, { useState } from 'react';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../../../assets/images/solwayhr_logo.jpeg'; // Replace with your logo path



const Header = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      expand="lg"
      bg="light"
      variant="light"
      fixed="top"
      expanded={expanded}
      className="header-navbar"
    >
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="HRApp Logo" className="header-logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link as={Link} to="/features" onClick={() => setExpanded(false)}>
              Features
            </Nav.Link>
            <Nav.Link as={Link} to="/pricing" onClick={() => setExpanded(false)}>
              Pricing
            </Nav.Link>
            <Nav.Link as={Link} to="/about" onClick={() => setExpanded(false)}>
              About Us
            </Nav.Link>
            {/* Add more Nav.Links as needed */}
          </Nav>
          <Nav className="ml-auto action-buttons">
            <Button
              variant="outline-primary"
              as={Link}
              to="/login"
              className="header-button"
              onClick={() => setExpanded(false)}
            >
              Login
            </Button>
            <Button
              variant="primary"
              as={Link}
              to="/demo"
              className="header-button"
              onClick={() => setExpanded(false)}
            >
              Request Demo
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
