// src/components/HomePage/AboutUs.js

import React from 'react';
import './AboutUs.css';
import teamPhoto from '../../assets/images/solwayhr_logo.jpeg'; // Replace with your image path

const AboutUs = () => {
  return (
    <section className="aboutus-section">
      <h2>About Us</h2>
      <div className="aboutus-content">
        <div className="aboutus-text">
          <p>
            At <strong>SolwayHR</strong>, our mission is to revolutionize the way businesses manage their human resources. We believe that efficient HR processes are the backbone of any successful organization. Our comprehensive HR solution is designed to simplify administrative tasks, enhance employee engagement, and ensure compliance with ever-evolving labor laws.
          </p>
          <p>
            Founded in 2024, our dedicated team of experts leverages the latest technologies to deliver a user-friendly and scalable platform tailored to meet the unique needs of enterprises across various industries.
          </p>
        </div>
        <div className="aboutus-image">
          <img src={teamPhoto} alt="Our Team" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
