import React from 'react';
import './CookiesPolicy.css'; 

import Header from '../Header/Header';



const CookiesPolicy = () => {
  return (
    <>
        <Header />
        <br />
        <br />
        <br />
        <div className="cookies-policy">

        <h1>Cookies Policy</h1>
        <p><em>Last updated on March 22, 2024</em></p>

        <h2>1. What is a Cookie?</h2>
        <p>
            A cookie is a small text file placed and stored for a limited period on your computer, tablet, smartphone, or any other device that allows you to browse our website and/or application (hereinafter referred to as the "Services"). Cookies enable SolwayHR to recognize you, remember certain information about your preferences or your journey on our Services, and understand how you use our services. Cookies also allow us to offer targeted advertising.
        </p>

        <h2>2. Who Places the Cookies?</h2>
        <p>
            Cookies are placed on your device by SolwayHR or by third parties.
        </p>

        <h2>3. How Does SolwayHR Use Cookies?</h2>
        <p>SolwayHR uses four types of cookies:</p>
        <ul>
            <li>
            <strong>Strictly Necessary Cookies:</strong> These cookies are essential for navigating our website or using our application. They cannot be configured because their deletion could cause navigation difficulties on our Services.
            </li>
            <li>
            <strong>Functional Cookies:</strong> These cookies allow us to recognize you when you return to our Services and provide content tailored to your preferences (language, display settings, etc.).
            </li>
            <li>
            <strong>Performance Cookies:</strong> These cookies provide us with statistical data on the traffic and usage of our Services. This information helps us understand your interactions with our Services and improve them accordingly.
            </li>
            <li>
            <strong>Marketing or Audience Measurement Cookies:</strong> These cookies, including those like Realytics, help keep track of your visits to our Services, the pages you have viewed, and the links you have clicked. Their purpose is to display relevant advertisements tailored to your interests.
            </li>
        </ul>

        <h2>4. How Long Do We Keep Your Cookies?</h2>
        <p>
            Your cookies are retained by SolwayHR for a period of 12 months before being deleted.
        </p>

        <h2>5. Managing Cookies</h2>
        <p>
            You can disable cookies at any time using our cookie management tool or by changing your browser settings to delete cookies.
        </p>
        <p>
            However, we would like to remind you that configuring cookies may alter your access conditions to our Services and your browsing comfort.
        </p>
        <p>
            Each browser's configuration is different. It is described in your browser's help menu, which will allow you to know how to modify your preferences regarding cookies.
        </p>
        <ul>
            <li>
            <strong>For Chrome:</strong>{' '}
            <a
                href="https://support.google.com/chrome/answer/95647?hl=en"
                target="_blank"
                rel="noopener noreferrer"
            >
                https://support.google.com/chrome/answer/95647?hl=en
            </a>
            </li>
            <li>
            <strong>For Internet Explorer:</strong>{' '}
            <a
                href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                target="_blank"
                rel="noopener noreferrer"
            >
                https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
            </li>
            <li>
            <strong>For Safari:</strong>{' '}
            <a
                href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                target="_blank"
                rel="noopener noreferrer"
            >
                https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
            </a>
            </li>
            <li>
            <strong>For Firefox:</strong>{' '}
            <a
                href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                target="_blank"
                rel="noopener noreferrer"
            >
                https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox
            </a>
            </li>
            <li>
            <strong>For Opera:</strong>{' '}
            <a
                href="https://help.opera.com/en/latest/web-preferences/#cookies"
                target="_blank"
                rel="noopener noreferrer"
            >
                https://help.opera.com/en/latest/web-preferences/#cookies
            </a>
            </li>
        </ul>
        <p>
            In any case, you can find more information on behavioral advertising and online privacy at the following address:
            <br />
            <a
            href="http://www.youronlinechoices.com/"
            target="_blank"
            rel="noopener noreferrer"
            >
            http://www.youronlinechoices.com/
            </a>
        </p>
        <p>
            And about Google at the following address:
            <br />
            <a
            href="https://policies.google.com/technologies/partner-sites"
            target="_blank"
            rel="noopener noreferrer"
            >
            https://policies.google.com/technologies/partner-sites
            </a>
        </p>
        </div>
    </>
    
  );
};

export default CookiesPolicy;
