import useAuth from "./useAuth";
import axios from '../utils/axiosInstance';


const useRefreshToken = () => {

    const { setAuth } = useAuth();

    const refresh = async () => {
        try {

            const response = await axios.post('/auth/refresh');
            const data = response.data;
            
            let accessToken = data.accessToken;
            let userId = data.userId;
            let username = data.username;
            let email = data.email;
            let roles = data.roles;
           
            setAuth({ accessToken, userId, username, email, roles });
           

            return accessToken;

        } catch (error) {

            setAuth({});
            throw error;

        }
    };

    return refresh;

};

export default useRefreshToken;