// src/components/HomePage/HeroSection.js

import React from 'react';
import { useTranslation } from 'react-i18next';


import './HeroSection.css';
import heroImage from '../../assets/images/solwayhr_logo.jpeg'; // Replace with your image path

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>{t('HeroSectionPhrase1')}</h1>
        <p>{t('HeroSectionPhrase2')}</p>
        <div className="hero-cta">
          <button className="primary">{t('HeroSectionBtn1')}</button>
          <button className="secondary">{t('HeroSectionBtn2')}</button>
        </div>
      </div>
      <div className="hero-image">
        <img src={heroImage} alt="HR Management Illustration" />
      </div>
    </section>
  );
};

export default HeroSection;
