// src/components/HomePage/Pricing.js

import React from 'react';
import './Pricing.css';

const Pricing = () => {
  const pricingPlans = [
    {
      name: 'Basic',
      price: '$29/month',
      features: ['Automated Payroll', 'Employee Management', 'Absence Tracking'],
      buttonText: 'Choose Basic',
    },
    {
      name: 'Pro',
      price: '$59/month',
      features: ['All Basic Features', 'Training Management', 'Advanced Reporting'],
      buttonText: 'Choose Pro',
    },
    {
      name: 'Enterprise',
      price: 'Contact Us',
      features: ['All Pro Features', 'Custom Integrations', 'Dedicated Support'],
      buttonText: 'Contact Sales',
    },
  ];

  return (
    <section className="pricing-section">
      <h2>Pricing Plans</h2>
      <div className="pricing-grid">
        {pricingPlans.map((plan, index) => (
          <div className="pricing-card" key={index}>
            <h3>{plan.name}</h3>
            <p className="price">{plan.price}</p>
            <ul>
              {plan.features.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
            <button className="btn primary-btn">{plan.buttonText}</button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Pricing;
