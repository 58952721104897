
import React from "react";
import AppRouter from "./routes/AppRoutes";



function App() {

  

  return (
    <AppRouter />
  );
}

export default App;
