import axios from "axios";

// const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:8080/api";
//const BASE_URL = "https://solwayhr.com:8080/api/";

//const BASE_URL = process.env.REACT_APP_API_BASE_URL

//const BASE_URL = "http://localhost:8080/api";
const BASE_URL = "https://api.solwayhr.com/api";

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true, // Include cookies with requests 
});

export default axiosInstance; 


