import React, { useState } from 'react';
import './ContactSupport.css';
import axios from '../../../utils/axiosInstance';

import Header from '../Header/Header';

const ContactSupport = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    category: 'General Inquiry',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState({
    submitted: false,
    success: false,
    message: '',
  });

  const categories = [
    'Technical Support',
    'Billing',
    'Sales Inquiry',
    'General Inquiry',
  ];

  const validateForm = () => {
    const { name, email, subject, message } = formData;
    const newErrors = {};

    if (!name.trim()) newErrors.name = 'Name is required.';
    if (!email.trim()) {
      newErrors.email = 'Email is required.';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) newErrors.email = 'Invalid email address.';
    }
    if (!subject.trim()) newErrors.subject = 'Subject is required.';
    if (!message.trim()) newErrors.message = 'Message is required.';

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      // Send form data to the backend
      //const response = await axios.post('/api/support/contact', formData);
       await axios.post('/support/contact', formData);

      setSubmitStatus({
        submitted: true,
        success: true,
        message: 'Your message has been sent successfully.',
      });
      setFormData({
        name: '',
        email: '',
        category: 'General Inquiry',
        subject: '',
        message: '',
      });
    } catch (error) {
      setSubmitStatus({
        submitted: true,
        success: false,
        message:
          'An error occurred while sending your message. Please try again later.',
      });
    }
  };

  return (
    <>
    
    
    <Header />
        <br />
        <br />
        <br />
    <div className="contact-support">
      <h1>Contact Support</h1>
      <p>
        If you have any questions, concerns, or need assistance, please fill out
        the form below, and our support team will get back to you as soon as
        possible.
      </p>

      {submitStatus.submitted && (
        <div
          className={
            submitStatus.success ? 'success-message' : 'error-message'
          }
        >
          {submitStatus.message}
        </div>
      )}

      <form onSubmit={handleSubmit} noValidate>
        <div className="form-group">
          <label htmlFor="name">
            Name<span className="required">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={errors.name ? 'error' : ''}
          />
          {errors.name && <small className="error-text">{errors.name}</small>}
        </div>

        <div className="form-group">
          <label htmlFor="email">
            Email<span className="required">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={errors.email ? 'error' : ''}
          />
          {errors.email && (
            <small className="error-text">{errors.email}</small>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            {categories.map((cat) => (
              <option value={cat} key={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="subject">
            Subject<span className="required">*</span>
          </label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className={errors.subject ? 'error' : ''}
          />
          {errors.subject && (
            <small className="error-text">{errors.subject}</small>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="message">
            Message<span className="required">*</span>
          </label>
          <textarea
            id="message"
            name="message"
            rows="6"
            value={formData.message}
            onChange={handleChange}
            className={errors.message ? 'error' : ''}
          ></textarea>
          {errors.message && (
            <small className="error-text">{errors.message}</small>
          )}
        </div>

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
    
    </>
  );
};

export default ContactSupport;
