import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const ProtectedRoute = ({ allowedRoles }) => {

    const { auth } = useAuth();


    if (auth?.accessToken === undefined) {
        // Still loading authentication state
        return null; // Or a loading indicator
      }

    if (!auth?.userId) {
        return <Navigate to="/login" replace />;
    }

    if (allowedRoles && !allowedRoles?.some((role) => auth.roles.includes(role))) {
        return <Navigate to="/unauthorized" replace />;
    }

    return <Outlet />;

};

export default ProtectedRoute;