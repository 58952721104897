import React from 'react';

import Logout from '../Auth/Logout';


const Dashboard = () => {

    // const axiosPrivate = useAxiosPrivate(); 
    // const [data, setData] = useState(null);
    // const navigate = useNavigate();


    return (
        <div>
            <h1>Dashboard : Hello SOLWAY HR WORLD !</h1>
            < Logout />
        </div>
    )

    

};

export default Dashboard;