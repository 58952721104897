import React from 'react';
import { Helmet } from 'react-helmet-async';
import CookieConsent from 'react-cookie-consent';
// import { getCookieConsentValue } from 'react-cookie-consent';

import HeroSection from './HeroSection';
import Features from './Features';
import Benefits from './Benefits';
import Testimonials from './Testimonials';
import Pricing from './Pricing';
import AboutUs from './AboutUs';
import ContactForm from './ContactForm';
import Footer from './Footer';
import Header from './Header/Header';

const Home = () => {
  const handleAcceptCookie = () => {
    // Initialize analytics or other services here
    // Example: initializeGoogleAnalytics();
  };
  return (
    <>

      <Helmet>
                
                <title>SolwayHR</title>
                <meta name="home page" content="landing page of SolwayHR" />
                {/* Add more meta tags as needed  */}
      </Helmet>

      <div className="homepage-container">
        <Header />
        <HeroSection />
        <Features />
        <Benefits />
        <Testimonials />
        <Pricing />
        <AboutUs />
        <ContactForm />
        <Footer />


        <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        enableDeclineButton
        onAccept={handleAcceptCookie}
        cookieName="yourAppCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        declineButtonStyle={{ color: "#fff", fontSize: "13px" }}
        expires={150}
      >
        We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.{" "}
        <a href="/cookies-policy" style={{ color: "#FFD700" }}>
          Learn more
        </a>
      </CookieConsent>
      </div>
    
    
    
    </>
   
  );
};

export default Home;
