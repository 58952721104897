import axios from "../utils/axiosInstance";

export const login = async (credentials) => {
    return axios.post('auth/login', credentials);
}

export const logout = async () => {
    return axios.post("auth/logout");
}

export const refreshAccessToken = async () => {
    return axios.post("auth/refresh");
}