// src/components/HomePage/Features.js

import React from 'react';
import './Features.css';
import feature1 from '../../assets/images/solwayhr_logo.jpeg';
import feature2 from '../../assets/images/solwayhr_logo.jpeg';
import feature3 from '../../assets/images/solwayhr_logo.jpeg';
import feature4 from '../../assets/images/solwayhr_logo.jpeg';

const Features = () => {
  const featureList = [
    {
      icon: feature1,
      title: 'Automated Payroll',
      description: 'Simplify your payroll process with our automated system that ensures accuracy and compliance.',
    },
    {
      icon: feature2,
      title: 'Employee Management',
      description: 'Efficiently manage employee records, track performance, and handle administrative tasks with ease.',
    },
    {
      icon: feature3,
      title: 'Absence Tracking',
      description: 'Monitor and manage employee absences, leave requests, and attendance seamlessly.',
    },
    {
      icon: feature4,
      title: 'Training & Development',
      description: 'Organize training programs, track participation, and foster employee growth and development.',
    },
  ];

  return (
    <section className="features-section">
      <h2>Features</h2>
      <div className="features-grid">
        {featureList.map((feature, index) => (
          <div className="feature-card" key={index}>
            <img src={feature.icon} alt={`${feature.title} Icon`} />
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
