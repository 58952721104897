import { useEffect } from "react";
import axios from "../utils/axiosInstance";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

// Request Interceptor: Adds the access token to the request headers if not already present.
// Response Interceptor: Refreshes the access token when a 401 error occurs and retries the failed request.

const useAxiosPrivate = () => {

    const { auth } = useAuth();
    const refresh = useRefreshToken();

    useEffect(
        () => {

            const requestIntercept = axios.interceptors.request.use(
                (config) => {
                    if(!config.headers['Authorization'] && auth?.accessToken) {
                        config.headers['Authorization'] = `Bearer ${auth.accessToken}`;
                    }
                    return config;
                },
                (error) => Promise.reject(error)
            );

            const responseIntercept = axios.interceptors.response.use(

                (response) => response, 
                async (error) => {
                    const prevRequest = error?.config;
                    if (
                        error?.response?.status === 401 &&
                        !prevRequest?.sent &&
                        !prevRequest.url.includes('/auth/login')
                    ) {
                        prevRequest.sent = true;
                        try {
                            const newAccessToken = await refresh();
                            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                            return axios(prevRequest);
                        } catch (err) {
                            return Promise.reject(err);
                        }
                    }

                    return Promise.reject(error);
                }

            );


            return () => {
                axios.interceptors.request.eject(requestIntercept);
                axios.interceptors.response.eject(responseIntercept);
            }


        }, 
        [auth, refresh]
    
        
    );



    return axios; 



};

export default useAxiosPrivate;
