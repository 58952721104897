// src/components/HomePage/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import LanguageSwitcher from '../Common/LanguageSwitcher';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer">
      <div className="footer-links">
      
        <Link to="/cookies-policy">Cookies Policy</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-of-service">Terms of Service</Link>
        <Link to="/contact-support">Contact Support</Link>
      </div>
      <div className="footer-social">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
      </div>
      <div className="footer-info">
        <p>SolwayHR © {currentYear}. All rights reserved.</p>
      </div>

      <LanguageSwitcher />

    </footer>
  );
};

export default Footer;
