import React from "react";
import { Routes, Route } from "react-router-dom";



import Dashboard from "../components/Dashboard/Dashboard";
import ProtectedRoute from "../components/Common/ProtectedRoute";
import NotFound from "../components/Pages/NotFound";


// Landing Page 
import Home from "../components/PublicLanding/Home";
import DemoRequestPage from "../components/PublicLanding/demo/DemoRequestPage";

// Auth 
import Login from "../components/Auth/Login";
import Register from "../components/Auth/Register";
import CookiesPolicy from "../components/PublicLanding/cookiesPolicy/CookiesPolicy";
import PrivacyPolicy from "../components/PublicLanding/privacyPolicy/PrivacyPolicy";
import TermsOfService from "../components/PublicLanding/termsOfService/TermsOfService";
import ContactSupport from "../components/PublicLanding/contactSupport/ContactSupport";

const AppRouter = () => {

    return (

        <Routes>


            {/* Pablic Landing  */}

            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/demo-request" element={<DemoRequestPage />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/contact-support" element={<ContactSupport />} />



            {/* Auth  */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />




            
            <Route element={<ProtectedRoute allowedRoles={['ROLE_USER']} />} >
                <Route path="/dashboard" element={<Dashboard />} />
                {/* Additional protected routes */}
            </Route>


            <Route path="*" element={<NotFound />} />



        </Routes>

    );


};

export default AppRouter;

