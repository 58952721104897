// src/components/HomePage/Testimonials.js

import React from 'react';
import './Testimonials.css';
import testimonial1 from '../../assets/images/solwayhr_logo.jpeg';
import testimonial2 from '../../assets/images/solwayhr_logo.jpeg';
import testimonial3 from '../../assets/images/solwayhr_logo.jpeg';

const Testimonials = () => {
  const testimonials = [
    {
      photo: testimonial1,
      name: 'Alice Johnson',
      position: 'HR Manager at TechCorp',
      feedback: 'This HR application has transformed our payroll processing and employee management. Highly recommended!',
    },
    {
      photo: testimonial2,
      name: 'Bob Smith',
      position: 'CEO at InnovateX',
      feedback: 'A comprehensive solution that streamlined our HR operations and improved overall efficiency.',
    },
    {
      photo: testimonial3,
      name: 'Carol Lee',
      position: 'Team Lead at Creatives Inc.',
      feedback: 'The user-friendly interface and robust features have made managing our workforce a breeze.',
    },
  ];

  return (
    <section className="testimonials-section">
      <h2>What Our Clients Say</h2>
      <div className="testimonials-grid">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <img src={testimonial.photo} alt={`${testimonial.name}`} />
            <h3>{testimonial.name}</h3>
            <h4>{testimonial.position}</h4>
            <p>"{testimonial.feedback}"</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
