import React from 'react'
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const Logout = () => {

    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const handleLogout = async () => {
        try {

            await axiosPrivate.post("/auth/logout");

        } catch (error) {
            console.error("Error during logout :", error);
        } finally {
            setAuth({});
            navigate("/login");
        }
    }

    return <button onClick={handleLogout}>logout</button>


};

export default Logout;


