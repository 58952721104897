import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";

// Forms
import Select from 'react-select';
import countryList from 'react-select-country-list';
import PhoneInput from 'react-phone-input-2';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { submitDemoRequest } from "../../../services/demoRequestService";

import "./DemoRequestPage.css";
import 'react-phone-input-2/lib/style.css';

// Remove the line 'YupPhone(Yup);' as it's not needed

const Demo = () => {

    const initialValues = {
        firstName: '',
        lastName: '',
        country: null,
        phoneNumber: '',
        email: '',
        company: '',
    };

    const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10,15}$/;

    const validationSchema = Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        country: Yup.object()
            .shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
            })
            .nullable()
            .required('Country is required'),
        phoneNumber: Yup.string()
            .matches(phoneRegExp, 'Invalid phone number')
            .required('Phone number is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        company: Yup.string().required('Company name is required'),
    });

    const countryOptions = useMemo(() => countryList().getData(), []);

    const handleSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

        const dataToSubmit = {
            ...values,
            country: values.country.label, // Or use .value for country code
        };
        try {
            await submitDemoRequest(dataToSubmit);
            setStatus({ success: 'Demo request submitted successfully.' });
            resetForm();
        } catch (error) { 
            if (error.response && error.response.data) {
                // Handle validation errors from the backend
                setStatus({ error: error.response.data });
            } else {
                setStatus({ error: 'An unexpected error occurred. Please try again later.' });
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Demo | SolwayHR</title>
                <meta name="login page" content="login page of SolwayHR" />
                {/* Add more meta tags as needed */}
            </Helmet>

            <div className="demo-request-page">
                <h1>Request a Demo</h1>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, status, setFieldValue, values }) => (
                        <Form>
                            <div>
                                <label htmlFor="firstName">First Name</label>
                                <Field name="firstName" type="text" />
                                <ErrorMessage name="firstName" component="div" className="error" />
                            </div>
                            <div>
                                <label htmlFor="lastName">Last Name</label>
                                <Field name="lastName" type="text" />
                                <ErrorMessage name="lastName" component="div" className="error" />
                            </div>
                            <div>
                                <label htmlFor="country">Country</label>
                                <Select
                                    options={countryOptions}
                                    name="country"
                                    value={values.country}
                                    onChange={(option) => setFieldValue('country', option)}
                                />
                                <ErrorMessage name="country" component="div" className="error" />
                            </div>
                            <div>
                                <label htmlFor="phoneNumber">Phone Number</label>
                                <PhoneInput
                                    country={'ma'} // Default country
                                    value={values.phoneNumber}
                                    onChange={(phone) => setFieldValue('phoneNumber', phone)}
                                    inputProps={{
                                        name: 'phoneNumber',
                                        required: true,
                                        autoFocus: false,
                                    }}
                                    enableSearch={true}
                                />
                                <ErrorMessage name="phoneNumber" component="div" className="error" />
                            </div>
                            <div>
                                <label htmlFor="email">Email</label>
                                <Field name="email" type="email" />
                                <ErrorMessage name="email" component="div" className="error" />
                            </div>
                            <div>
                                <label htmlFor="company">Company</label>
                                <Field name="company" type="text" />
                                <ErrorMessage name="company" component="div" className="error" />
                            </div>
                            {status && status.success && (
                                <div className="success-message">{status.success}</div>
                            )}
                            {status && status.error && (
                                <div className="error-message">
                                    {typeof status.error === 'string'
                                        ? status.error
                                        : JSON.stringify(status.error)}
                                </div>
                            )}
                            <button type="submit" disabled={isSubmitting}>
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default Demo;
