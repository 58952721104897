import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/authService';


const Login = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    });

    const [errorMsg, setErrorMsg] = useState('');

    const handleChange = (e) => {
        setCredentials(
            (prev) => ({
                ...prev, [e.target.name]: e.target.value,
            })
        )
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            const response = await login(credentials);
            
            const data = response.data;
            
            let accessToken = data.accessToken;
            let userId = data.userId;
            let username = data.username;
            let email = data.email;
            let roles = data.roles;
           
            setAuth({ accessToken, userId, username, email, roles });
            navigate("/dashboard");

        } catch (error) {
            setErrorMsg("Invalid username or password");
        }

    };


    return (
        <>
            <Helmet>
                
                <title>Login | SolwayHR</title>
                <meta name="login page" content="login page of SolwayHR" />
                {/* Add more meta tags as needed  */}
            </Helmet>

           <div className="login-container">
                <h2>Login</h2>

                {errorMsg && <p className="error">{errorMsg}</p>}

                <form onSubmit={handleSubmit} >

                    <input 

                        type="text"
                        name="username"
                        placeholder="Username"
                        value={credentials.username}
                        onChange={handleChange}
                        required

                    /> <br />

                    <input 

                        type="password"
                        name="password"
                        placeholder="Password"
                        value={credentials.password}
                        onChange={handleChange}
                        required
                        
                    /> <br />

                    <button type="submit">Login</button>


                </form>







           </div>

             
        </>
    );

};

export default Login;